require('./bootstrap');
$(function () {


    

    $(document).on('change', '#change-type input', function () {
        var val = ($(this).val());
        $('.show-' + val).removeClass('d-none');
        $('.not-' + val).addClass('d-none');
        $('.show-' + val + ' input').attr('required', 'required');
        $('.show-' + val + ' select').attr('required', 'required');
        $('.not-' + val + ' input').removeAttr('required');
        $('.not-' + val + ' select').removeAttr('required');

        var attr = $('.special-combine input').attr('place-' + val);
        $('.special-combine input').attr('placeholder', attr);
    });
    $(document).on('change', '.inputfile', function () {
        var text = 'Submeter fotografia (Proporção Recomendada: 1080x1920)';
        if ($(this)[0].files.length) {
            text = $(this)[0].files[0].name;
        }
        $(this).parent().find('.file-box').html(text);
    });

    $(document).on('click', '.float-menu .search', function (e) {
        $('.float-menu').addClass('active');
        $('.float-menu-parent').addClass('active');
    });
    $(document).on('click', '.float-menu .button', function () {
        $('.float-menu').toggleClass('active');
        $('.float-menu-parent').toggleClass('active');
    }); 
 
    $(document).on('change', '#unid', function () {
        $("option:first-child", '#unid').removeAttr('selected');
    });
    $(document).on('change', '#dir', function () {
        var optionSelected = $("option:selected", this).attr('attr');
        $('#unid > option').each(function (index) {
            if (index > 0) {
                if ($(this).attr('attr') == optionSelected) {
                    $(this).removeClass('d-none');
                } else {
                    $(this).addClass('d-none');
                }
            }
        });
        if ($("option:selected", '#unid').hasClass('d-none')) {
            $("option:selected", '#unid').removeAttr('selected');
            $("option:first-child", '#unid').attr('selected', 'selected');
        }
    });
});


const $tabsToDropdown = $(".tabs-to-dropdown");

function generateDropdownMarkup(container) {
    const $navWrapper = container.find(".nav-wrapper");
    const $navPills = container.find(".nav-pills");
    const firstTextLink = $navPills.find("li:first-child a").text();
    const $items = $navPills.find("li");
    const markup = `
    <div class="dropdown d-md-none">

      <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <span>
        ${firstTextLink}
        </span>
        <svg xmlns="http://www.w3.org/2000/svg" width="19.095" height="11.047" viewBox="0 0 19.095 11.047">
        <path id="Path_1767" data-name="Path 1767" d="M498.842,3107.295l7.426,7.426,7.426-7.426" transform="translate(-496.72 -3105.174)" fill="none" stroke="#e6041e" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
        </svg>

      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton"> 
        ${generateDropdownLinksMarkup($items)}
      </div>
    </div>
  `;
    $navWrapper.prepend(markup);
}

function generateDropdownLinksMarkup(items) {
    let markup = "";
    items.each(function () {
        const textLink = $(this).find("a").text();
        markup += `<a class="dropdown-item" href="#">${textLink}</a>`;
    });

    return markup;
}

function showDropdownHandler(e) {
    // works also
    //const $this = $(this);
    const $this = $(e.target);
    const $dropdownToggle = $this.find(".dropdown-toggle");
    const dropdownToggleText = $dropdownToggle.find('span').text().trim();
    const $dropdownMenuLinks = $this.find(".dropdown-menu a");
    const dNoneClass = "d-none";
    var counter = 0;
    $dropdownMenuLinks.each(function (index) {
        const $this = $(this);
        $this.removeClass('odd');
        if ($this.text() == dropdownToggleText) {
            $this.addClass(dNoneClass);
        } else {
            counter++;
            if (counter % 2 == 1) {
                $this.addClass('odd');
            }
            $this.removeClass(dNoneClass);
        }
    });
}

function clickHandler(e) {
    e.preventDefault();
    const $this = $(this);
    const index = $this.index();
    const text = $this.text();
    $this.closest(".dropdown").find(".dropdown-toggle").find('span').text(`${text}`);
    $this
        .closest($tabsToDropdown)
        .find(`.nav-pills li:eq(${index}) a`)
        .tab("show");
}

function shownTabsHandler(e) {
    // works also
    //const $this = $(this);
    const $this = $(e.target);
    const index = $this.parent().index();
    const $parent = $this.closest($tabsToDropdown);
    const $targetDropdownLink = $parent.find(".dropdown-menu a").eq(index);
    const targetDropdownLinkText = $targetDropdownLink.text();
    $parent.find(".dropdown-toggle").find('span').text(targetDropdownLinkText);
}

$tabsToDropdown.each(function () {
    const $this = $(this);
    const $pills = $this.find('a[data-toggle="pill"]');
 
    generateDropdownMarkup($this);

    const $dropdown = $this.find(".dropdown");
    const $dropdownLinks = $this.find(".dropdown-menu a");

    $dropdown.on("show.bs.dropdown", showDropdownHandler);
    $dropdownLinks.on("click", clickHandler);
    $pills.on("shown.bs.tab", shownTabsHandler);
});
